import {
  SET_APPLICATION_LOADED,
} from '../actions/applicationProperties';

const initialState = {
  applicationLoaded: false
};

export default function projectsReducer (state = initialState, action) {
  switch (action.type) {
    case SET_APPLICATION_LOADED:
      return {
        ...state,
        applicationLoaded: true
      };

    default:
      return state;
  }
}