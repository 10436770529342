import React from 'react';
import { Tween, Timeline } from 'react-gsap';
import { Controller, Scene } from 'react-scrollmagic';

import styles from './Footer.module.css';

import SocialIcons from '../SocialIcons/SocialIcons';

class Footer extends React.Component {
  render () {
    return (
      <footer id='footer'>
        <Controller>
          <Scene
            offset={10}
            duration={0}
            triggerHook='onEnter'
            triggerElement='#footer'>
            {(progress, event) => (
              <Timeline
                duration={1}
                paused
                playState={
                  event.type === 'enter' && event.scrollDirection === 'FORWARD' ? 'play' :
                    event.type === 'leave' && event.scrollDirection === 'REVERSE' ? 'reverse' :
                      null
                }>
                <Tween
                  from={{ opacity: 0, y: 25 }}
                  to={{ opacity: 1, y: 0 }}
                  ease='Back.easeInOut'>
                  <div className={styles.footerContainer}>
                    <div className='grid-50 mobile-grid-100'>
                      <p className={styles.tagline}>digital first<br/>development studio</p>
                    </div>

                    <div className={`${styles.footerContentContainer} grid-50`}>
                      <div className={`${styles.contactDetails} grid-55 mobile-grid-100`}>
                        <h2>contact</h2>
                        {/* TODO: confirm contact email */}
                        <div className={styles.contact}>
                          <a
                            href='mailto:team@whtspc.com.au'
                            target='_blank'
                            rel='noopener noreferrer'>team@whtspc.com.au
                          </a>
                        </div>
                      </div>

                      <div className={`${styles.socialDetails} grid-45 mobile-grid-100`}>
                        <h2>social</h2>
                        <SocialIcons
                          horizontal />
                      </div>
                    </div>
                  </div>
                </Tween>
              </Timeline>
            )}
          </Scene>
        </Controller>
      </footer>
    );
  }
}

export default Footer;
