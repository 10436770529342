import ContentHelper from '../helpers/ContentHelper';

export const GET_PROJECTS_START   = 'GET_PROJECTS_START';
export const GET_PROJECTS_SUCCESS = 'GET_PROJECTS_SUCCESS';
export const GET_PROJECTS_FAILURE = 'GET_PROJECTS_FAILURE';

export const getProjectsStart = () => ({
  type: GET_PROJECTS_START
});

export const getProjectsSuccess = projects => ({
  type: GET_PROJECTS_SUCCESS,
  payload: { projects }
});

export const getProjectsFailure = error => ({
  type: GET_PROJECTS_FAILURE,
  payload: { error }
});

export function getProjects () {
  return async dispatch => {
    try {
      dispatch(getProjectsStart());

      let response = await new ContentHelper().retrieveRecords('projects');
      if (response) {
        dispatch(getProjectsSuccess(response));
      }
    }
    catch (error) {
      console.error('getProjects', error);
      dispatch(getProjectsFailure(error));
    }
  };
}