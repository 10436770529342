import ContentHelper from '../helpers/ContentHelper';

export const GET_SERVICES_START   = 'GET_SERVICES_START';
export const GET_SERVICES_SUCCESS = 'GET_SERVICES_SUCCESS';
export const GET_SERVICES_FAILURE = 'GET_SERVICES_FAILURE';

export const getServicesStart = () => ({
  type: GET_SERVICES_START
});

export const getServicesSuccess = services => ({
  type: GET_SERVICES_SUCCESS,
  payload: { services }
});

export const getServicesFailure = error => ({
  type: GET_SERVICES_FAILURE,
  payload: { error }
});

export function getServices () {
  return async dispatch => {
    try {
      dispatch(getServicesStart());

      let response = await new ContentHelper().retrieveRecords('services');

      if (response) {
        dispatch(getServicesSuccess(response));
      }
    }
    catch (error) {
      console.error('getServices', error);
      dispatch(getServicesFailure(error));
    }
  };
}