/* global window */
import React, { useEffect } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { useMediaQuery } from 'react-responsive';

import { getServices } from './actions/services';
import { getProjects } from './actions/projects';

import Home from './pages/Home/Home';
import Projects from './pages/Projects/Projects';
import ScrollToTop from './helpers/ScrollToTop';


const App = (props) => {
  const dispatch = useDispatch();
  const isMobileDevice = useMediaQuery({ maxHeight: '500px'});
  const scrollRestoration = window.history.scrollRestoration;

  useEffect(() => {
    if (scrollRestoration !== 'manual') {
      window.history.scrollRestoration = 'manual';
    }

    // dispatch event to retrieve Services from API
    dispatch(getServices());

    // dispatch event to retrieve Projects from API
    dispatch(getProjects());
  }, [dispatch, scrollRestoration]);

  const {
    servicesLoading,
    servicesList,
    projectsLoading,
    projectsList,
    projectsAssets
  } = props;

  if (isMobileDevice) {
    return (
      <div className='screenTooSmall'>
        <div className='content'>
          <h6>Error:</h6>
          <h6>Screen seems to small<br/>try rotating device</h6>
        </div>
      </div>
    );
  }

  if (servicesLoading || projectsLoading) {
    return null;
  }

  return (
    <>
      <Router>
        <ScrollToTop />
        <Switch>
          <Route exact path='/' render={(props) =>
            <Home
              {...props}
              servicesList={servicesList}
              projectsList={projectsList}
              projectsAssets={projectsAssets}
            />}
          />

          <Route exact path='/projects/:id?' render={(props) =>
            <Projects
              {...props}
              projectsList={projectsList}
              projectsAssets={projectsAssets}
            />}
          />

          {/* TODO: 404 page */}
          {/*<Route path='*'>
            <Redirect to='/' />
          </Route>*/}
        </Switch>
      </Router>
    </>
  );
};

App.propTypes = {
  dispatch: PropTypes.func,
  servicesLoading: PropTypes.bool,
  servicesList: PropTypes.array,
  projectsList: PropTypes.array,
  projectsAssets: PropTypes.array,
  projectsLoading: PropTypes.bool,
  projectsError: PropTypes.object
};

const mapStateToProps = state => ({
  servicesLoading: state.services.loading,
  servicesList: state.services.items,
  projectsList: state.projects.items,
  projectsAssets: state.projects.assets,
  projectsLoading: state.projects.loading,
  projectsError: state.projects.error
});

export default connect(mapStateToProps)(App);
