import {
  GET_PROJECTS_START,
  GET_PROJECTS_SUCCESS,
  GET_PROJECTS_FAILURE
} from '../actions/projects';

const initialState = {
  items: [],
  assets: [],
  loading: true,
  error: null
};

export default function projectsReducer (state = initialState, action) {
  switch (action.type) {
    case GET_PROJECTS_START:
      return {
        ...state,
        loading: true,
        error: null
      };

    case GET_PROJECTS_SUCCESS:
      return {
        ...state,
        loading: false,
        items: action.payload.projects.items,
        assets: action.payload.projects.includes.Asset
      };

    case GET_PROJECTS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        items: [],
        assets: []
      };

    default:
      return state;
  }
}