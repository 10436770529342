import * as contentful from 'contentful';

class ContentHelper {
  constructor () {
    this.client = contentful.createClient({
      space: process.env.REACT_APP_CONTENT_SPACE_ID,
      accessToken: process.env.REACT_APP_CONTENT_ACCESS_TOKEN
    });
  }

  async retrieveRecords (contentType, filter) {
    if (!contentType) {
      console.warn('[ContentHelper]: contentType not found', contentType);
      return;
    }

    let requestData = {
      'content_type': contentType
    };

    // apply additional filter if one is provided
    if (filter) {
      requestData = Object.assign(requestData, filter);
    }

    let response = await this.client.getEntries(requestData);

    if (process.env.NODE_ENV === 'development' && process.env.REACT_APP_ENABLE_LOGS) {
      console.log(contentType, response);
    }

    return response;
  }

  async retrieveAsset (assetId) {
    if (!assetId) {
      console.warn('[ContentHelper]: assetId not found', assetId);
      return;
    }

    let response = await this.client.getAsset(assetId);

    if (process.env.NODE_ENV === 'development' && process.env.ENABLE_LOGS) {
      console.log(assetId, response);
    }

    return response;
  }
}

export default ContentHelper;