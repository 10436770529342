import React from 'react';
import PropTypes from 'prop-types';

import styles from './ProjectContent.module.css';

const ProjectContent = ({ projectData }) => {
  return (
    <div className={styles.projectPageContent}>
      <div className={styles.projectContent}>
        <p>
          {projectData.projectDescription}
        </p>
        {projectData?.tags &&
          <ul className={styles.tagsList}>
            {projectData?.tags.map(tag =>
              <li key={tag.toLowerCase()}>{tag}</li>
            )}
          </ul>
        }
        {projectData?.imageGallery &&
          <div className={styles.imageGallery}>
            {projectData?.imageGallery.map((image, key) =>
              <img key={key} src={image.fields.file.url} alt={image.fields.file?.fileName ?? ''}/>
            )}
          </div>
        }
      </div>
    </div >
  );
};

ProjectContent.propTypes = {
  projectData: PropTypes.object,
  projectNumber: PropTypes.number,
  projectId: PropTypes.string
};

export default ProjectContent;