import React, { useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import { TimelineMax } from 'gsap';
import { useIntersection } from 'react-use';
import { Redirect } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';

import { filterEntryById } from '../../helpers/CommonHelper';

import styles from './ProjectsList.module.css';

const ProjectsList = ({ projects, assets }) => {
  const [navigate, setNavigate] = useState({ status: false, id: '' });

  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });

  const projectListSectionRef = useRef(null);
  const projectListIntersection = useIntersection(projectListSectionRef, {
    root: null,
    rootMargin: '0px',
    threshold: isMobile ? 0.5 : 1
  });

  const fadeIn = (element) => {
    new TimelineMax()
      .to(element.current,
        {
          duration: 0.8,
          ease: 'power2.out',
          y: -80,
          opacity: 1,
        });
  };

  useEffect(
    () => {
      if (projectListIntersection && projectListIntersection.intersectionRatio > 0) {
        fadeIn(projectListSectionRef);
      }
    },
    [projectListIntersection]
  );

  const findImageForProject = (project) => {
    if (!assets || !project.fields.image) {
      return null;
    }

    return filterEntryById(assets, project.fields.image.sys.id);
  };

  const redirectToFeaturedProject = (e, id) => {
    setNavigate({ status: true, id: id });
  };

  if (navigate.status) {
    return (
      <Redirect
        to={{pathname: '/projects/' + navigate.id}}
        push
      />
    );
  }

  return (
    <section ref={projectListSectionRef} className={styles.projectListSection}>
      <div className={styles.projectsContainer}>
        <ul className={styles.projectsList}>
          {projects && projects.map((project) =>
            <li
              key={project.sys.id}
              className={styles.projectItem}>
              <button
                onClick={(e) => redirectToFeaturedProject(e, project.sys.id)}
                style={{backgroundImage: `url(${findImageForProject(project).fields.file.url})`}}>
              </button>
            </li>
          )}
        </ul>
      </div>
    </section>
  );
};

ProjectsList.propTypes = {
  projects: PropTypes.array,
  assets: PropTypes.array
};

export default ProjectsList;
