import { combineReducers } from 'redux';

import services from './services';
import projects from './projects';
import application from './applicationProperties';

export default combineReducers({
  application,
  services,
  projects
});