import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { TimelineMax } from 'gsap';

import styles from './Services.module.css';
import { useIntersection } from 'react-use';
import ServiceList from './ServiceList';

const Services = ({ services }) => {
  const featureTitleRef = useRef(null);
  const fetaureTitleIntersection = useIntersection(featureTitleRef, {
    root: null,
    rootMargin: '0px',
    threshold: 1
  });

  const fadeIn = (element) => {
    new TimelineMax()
      .to(element.current,
        {
          duration: 0.8,
          ease: 'power4.out',
          y: -80,
          opacity: 1,
        })
      .set(element.current,
        {
          className: '+=active'
        });
  };

  useEffect(
    () => {
      if (fetaureTitleIntersection && fetaureTitleIntersection.intersectionRatio > 0) {
        fadeIn(featureTitleRef);
      }
    },
    [fetaureTitleIntersection]
  );

  return (
    <div className={styles.services}>
      <div className={styles.titleContainer}>
        <h1 ref={featureTitleRef}>What do<br/>we do?</h1>
      </div>
      <div className={styles.featuredProjectContent}>
        {services && services.map((service, key) =>
          <ServiceList
            key={key}
            title={service.fields.title}
            items={service.fields.serviceList} />
        )}
      </div>
    </div>
  );
};

Services.propTypes = {
  services: PropTypes.array
};

export default Services;
