/* global document */
import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

import styles from './Logo.module.css';

const Logo =  (props) => {
  const { color } = props;

  let history = useHistory();

  const homeLink = () => {
    document.body.classList.remove('menu-open');

    history.push('/');
  };

  return (
    <button className={styles.logo} onClick={() => homeLink()}>
      <svg style={color ? {fill: color} : null}>
        <g>
          <path className="st0" d="M48.1,13.7v10.5h-5.5V14.1c0-2-1.2-3.4-3.3-3.4c-2,0-3.3,1.4-3.3,3.4v10.1h-5.4V0.6L35.6,0L36,0.2v7.5
          C37.2,6.7,38.7,6,40.7,6C45.1,6,48.1,9.1,48.1,13.7z M64.4,22.8l-0.2,0.4c-1.2,0.9-2.8,1.4-4.5,1.4c-3.5,0-6.6-2-6.6-6.7v-7.5h-3.1
          V6.5h3.1V1.2l5-0.5l0.4,0.2v5.5h5.2v4.1h-5.2v7c0,1.8,0.9,2.5,2.1,2.5c0.6,0,1.3-0.2,1.8-0.7l0.4,0.2L64.4,22.8z M65.4,21.4v-0.4
          l2.5-2.7h0.4c1.1,1.2,3.2,2.3,5.2,2.3c1.3,0,2.1-0.6,2.1-1.4c0-1.1-1.2-1.5-2.7-2c-3-0.9-6.4-2.1-6.4-5.8c0-3.3,2.8-5.4,7.1-5.4
          c3,0,5.8,1.1,7.1,2.9v0.4l-2.4,2.6h-0.4c-1.3-1.2-3-1.9-4.3-1.9c-1.1,0-1.9,0.5-1.9,1.2c0,1,1.6,1.4,3.8,2.2c3,1.2,5.3,2.5,5.3,5.9
          c0,3.3-3,5.4-7.4,5.4C70.1,24.7,67,23.4,65.4,21.4z M101.6,15.4c0,5.2-3.7,9.3-8.9,9.3c-1.8,0-3.5-0.6-4.9-1.5v6.5h-5.5V6.5h4.6v1.7
          C88.5,6.9,90.4,6,92.7,6C97.8,6,101.6,10.1,101.6,15.4z M96.2,15.4c0-2.6-1.7-4.6-4.4-4.6c-2.4,0-4.3,1.7-4.3,4.6
          c0,2.9,1.9,4.6,4.3,4.6C94.4,20,96.2,18,96.2,15.4z M103,15.4c0-5.4,4.1-9.3,9.7-9.3c3.3,0,5.8,1.4,7.4,3.1v0.4l-3.1,2.8h-0.4
          c-0.9-1-2.2-1.6-3.7-1.6c-2.6,0-4.4,2-4.4,4.6c0,2.7,1.8,4.6,4.5,4.6c1.4,0,2.7-0.5,3.6-1.6h0.4l3.1,2.8v0.4
          c-1.6,1.8-4.1,3.1-7.4,3.1C107.1,24.7,103,20.7,103,15.4z M17.4,6.5h-0.6h-4.3L8.7,17.7L5.5,6.5H0.4H0l6.3,17.8h5.2l3.6-10.6
          l2.1-6.2L17.4,6.5z M27.8,6.5h-5.1l-6.6,17.8h0l5.6-0.1l6.5-17.8H27.8z"/>
        </g>
      </svg>
    </button>
  );
};

Logo.propTypes = {
  color: PropTypes.string
};

export default Logo;
