import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useMediaQuery } from 'react-responsive';
import axios from 'axios';
import Logo from '../Logo/Logo';
import SocialIcons from '../SocialIcons/SocialIcons';
import styles from './Menu.module.css';

const API_PATH = '/contact.php';

export const Menu = ({ menuClick }) => {
  const [formData, updateFormData] = useState({
    name: '',
    company: '',
    email: '',
    message: '',
  });
  const [formSubmitted, submitForm] = useState(false);
  const [formError, setFormError] = useState(null);

  const handleFormSubmit = (e) => {
    e.preventDefault();

    if (formData.name && formData.company && formData.email && formData.message) {
      console.log('submitting - ');
      axios({
        method: 'post',
        url: `${API_PATH}`,
        headers: { 'content-type': 'application/json' },
        data: formData
      })
        .then(() => {
          submitForm(true);
        })
        .catch(() => {
          setFormError('An unknown error occurred.');
        });
    }
    else {
      setFormError('All fields are required.');
    }
  };

  const renderForm = () => {
    if (formSubmitted) {
      return <h3>Thank you for reaching out we will get in touch with you soon.</h3>;
    }

    return (
      <form>
        <input name="name" type="text" placeholder="name" required
          onChange={(e) => updateFormData({ ...formData, name: e.target.value })} />
        <input name="company" type="text" placeholder="company name" required
          onChange={(e) => updateFormData({ ...formData, company: e.target.value })} />
        <input name="email" type="text" placeholder="email address" required
          onChange={(e) => updateFormData({ ...formData, email: e.target.value })} />
        <input name="message" type="text" placeholder="tell us how we can help" required
          onChange={(e) => updateFormData({ ...formData, message: e.target.value })} />
        {formError &&
          <p className={styles.errorMessage}>{formError}</p>
        }
        <button type="submit" onClick={handleFormSubmit}>Get in touch</button>
      </form>
    );
  };

  return (
    <nav className={styles.menuContainer}>
      <MenuNavigation
        menuClick={menuClick}
        themeLight />
      <div className={styles.menuContainer}>
        <div className={styles.menuContentContainer}>
          <div className={styles.menuContent}>
            <h2>make life easier for you and your customers.</h2>
          </div>
          <div className={styles.contactFormContainer}>
            {renderForm()}
          </div>
        </div>
      </div>
    </nav>
  );
};

Menu.propTypes = {
  menuClick: PropTypes.func
};


export const MenuNavigation = ({ menuClick, themeLight }) => {
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1080px)' });

  return (
    <>
      <Logo color={themeLight ? 'white' : 'black'} />
      <div className={`${styles.navigation} ${themeLight && styles.lightTheme}`}>
        <div className={`${styles.menuButton} ${themeLight ? styles.close : ''}`} onClick={menuClick}>

        </div>
        {/* ONLY SHOW SOCIAL ON DESKTOP */}
        {!isTabletOrMobile &&
          <div className={styles.socialsContainer}>
            {/*<p>Our socials</p>*/}
            <SocialIcons
              vertical
              light={themeLight ? true : false} />
          </div>
        }
      </div>
    </>
  );
};

MenuNavigation.propTypes = {
  menuClick: PropTypes.func,
  themeLight: PropTypes.bool
};
