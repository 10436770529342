import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { TimelineMax } from 'gsap';
import { useIntersection } from 'react-use';
import { useMediaQuery } from 'react-responsive';

import styles from './AboutSection.module.css';

const AboutSection = ({ text }) => {
  const aboutSectionRef = useRef(null);

  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });

  const aboutSectionIntersection = useIntersection(aboutSectionRef, {
    root: null,
    rootMargin: '0px',
    threshold: isMobile ? 0.7 : 1
  });

  const fadeIn = (element) => {
    new TimelineMax()
      .to(element.current,
        {
          duration: 0.8,
          ease: 'power4.out',
          y: -80,
          opacity: 1,
        });
  };

  useEffect(
    () => {
      if (aboutSectionIntersection && aboutSectionIntersection.intersectionRatio > 0) {
        fadeIn(aboutSectionRef);
      }
    },
    [aboutSectionIntersection]
  );

  return (
    <section ref={aboutSectionRef} className={styles.aboutSection}>
      <p>{text}</p>
    </section>
  );

};

AboutSection.propTypes = {
  text: PropTypes.string
};

export default AboutSection;