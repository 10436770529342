import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import rootReducer from './reducers/root';

/* eslint-disable no-undef */
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__;
/* eslint-enable */

export default function configureStore () {
  if (composeEnhancers) {
    return createStore(
      rootReducer,
      composeEnhancers(
        applyMiddleware(thunk)
      )
    );
  }
  return createStore(
    rootReducer,
    applyMiddleware(thunk)
  );
}