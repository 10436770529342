import React from 'react';
import PropTypes, { string } from 'prop-types';

import styles from './ServiceList.module.css';

const ServiceList = ({ title, items }) => {
  return (
    <div className={`${styles.serviceListContainer} grid-50 mobile-grid-100`}>
      <h5>{title}</h5>
      <ul className={styles.serviceList}>
        {items && items.map((item, key) =>
          <li key={key} className={styles.serviceItem}>{item}</li>
        )}
      </ul>
    </div>
  );
};

ServiceList.propTypes = {
  title: PropTypes.string,
  items: PropTypes.arrayOf(string)
};

export default ServiceList;
