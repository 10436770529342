/* global document */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import gsap, { TimelineMax } from 'gsap';
import { useMediaQuery } from 'react-responsive';

import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import Project from '../../components/Project/Project';
import ProjectContent from '../../components/Project/ProjectContent/ProjectContent';

import styles from './Projects.module.css';

const Projects = (props) => {

  const [currentProjectIndex, setCurrentProjectIndex] = useState(0);
  const [projectDetailsOpen, openProjectDetails] = useState(false);
  const [activeProjectId, setActiveProjectId] = useState(null);

  const isLaptop = useMediaQuery({ query: '(max-width: 1500px) and (max-height: 1100px)' });
  const isTablet = useMediaQuery({ query: '(max-width: 1080px)' });
  const isMobile = useMediaQuery({ query: '(max-width: 1260px)' });

  const projectSliderRef = React.createRef(null);
  const sliderRef = React.createRef(null);

  useEffect(() => {
    document.body.classList.add('projectPage');

    if (props.match.params.id) {
      const projectId = props.match.params.id;

      if (projectId && props.projectsList) {
        let projectIndex = props.projectsList.findIndex(element => element.sys.id === projectId);

        setActiveProjectId(projectId);
        setCurrentProjectIndex(projectIndex);

        if (projectIndex !== 0) {
          scrollProject(projectSliderRef.current, projectIndex, true, projectId);
          scrollSlider (sliderRef.current, projectIndex);
        } else {
          openProject (projectSliderRef.current, projectId);
        }
      }
    }

  }, []);

  const scrollProject = (element, projectIndex, isProjectOpen, projectId) => {
    let x = '';
    switch (projectIndex) {
      case 0: x = '0';
        break;
      case 1: x = '-100vw';
        break;
      case 2: x = '-200vw';
        break;
      default: x = '0';
        break;
    }

    let projectScroll = new TimelineMax()
      .to(element.childNodes, {
        scale: 0.75,
        duration: 0.5,
        ease: 'power4.inOut'
      })
      .to(element, {
        x: x,
        duration: 0.75,
        ease: 'power2.inOut'
      })
      .to(element.childNodes, {
        scale: 1,
        duration: 0.5,
        ease: 'power4.inOut'
      });

    if (isProjectOpen) {
      projectScroll.call(openProject, [projectSliderRef.current, projectId]);
    }
  };

  const scrollSlider = (element, projectIndex) => {
    let x = '';
    switch (projectIndex) {
      case 0: x = '0';
        break;
      case 1: x = '100%';
        break;
      case 2: x = '200%';
        break;
      default: x = '0';
        break;
    }

    gsap.to(element, {
      x: x,
      duration: 0.75,
      ease: 'power4.inOut'
    });
  };

  const handleNext = () => {
    let nextIndex = currentProjectIndex + 1;

    if (nextIndex >= props.projectsList.length) {
      nextIndex = 0;
    }

    if (projectDetailsOpen) {
      const childHtmlElements = projectSliderRef.current.children;
      animateFromProjectScreen(childHtmlElements.namedItem(activeProjectId), nextIndex);
    }

    if (!projectDetailsOpen) {
      scrollProject(projectSliderRef.current, nextIndex);
      scrollSlider(sliderRef.current, nextIndex);
    }

    setCurrentProjectIndex(nextIndex);
  };

  const openProject = (projectSlider, projectId) => {
    let childHtmlElements = projectSlider?.children;

    if (!childHtmlElements) {
      childHtmlElements = projectSliderRef.current.children;
    }

    setActiveProjectId(projectId);
    animateToProjectScreen(childHtmlElements.namedItem(projectId));
  };

  const animateToProjectScreen = (element) => {
    openProjectDetails(true);

    /* eslint-disable no-undef */
    document.body.classList.add('projectPageOpen');
    /* eslint-enable */

    const imageContainer = element.getElementsByClassName('imageContainer');
    const pageHeaderContent = element.getElementsByClassName('projectText');
    const projectHeaderContent = element.getElementsByClassName('projectPageHeader');

    let projectTextY = 420;
    if (isMobile) {
      projectTextY = 205;
    }
    if (isLaptop) {
      projectTextY = 150;
    }

    new TimelineMax()
      .to(imageContainer, {
        width: isTablet ? '95%' : 'calc(100vw - 24vw)',
        position: isTablet ? 'auto' : 'absolute'
      })
      .to(projectHeaderContent, {
        visibility: 'visible',
        duration: 0.05
      })
      .to(imageContainer, {
        y: isMobile ? 210 : 180,
        duration: 0.75,
        ease: 'power4.inOut'
      })
      .to(pageHeaderContent, {
        y: projectTextY,
        duration: 0.75,
        ease: 'power4.inOut',
      }, '-=0.75');
  };

  const animateFromProjectScreen = (element, nextIndex) => {
    openProjectDetails(false);

    /* eslint-disable no-undef */
    document.body.classList.remove('projectPageOpen');
    /* eslint-enable */

    const imageContainer = element.getElementsByClassName('imageContainer');
    const pageHeaderContent = element.getElementsByClassName('projectText');
    const projectHeaderContent = element.getElementsByClassName('projectPageHeader');

    new TimelineMax()
      .to(imageContainer, {
        y: 0,
        duration: 0.75,
        ease: 'power4.inOut'
      })
      .to(pageHeaderContent, {
        y: 0,
        duration: 0.75,
        ease: 'power4.inOut'
      }, '-=0.75')
      .to(projectHeaderContent, {
        visibility: 'hidden',
        duration: 0.1
      })
      .to(imageContainer, {
        width: isTablet ? '95%' : '55%',
        position: 'relative',
        duration: 0.3
      })
      .call(scrollSlider, [sliderRef.current, nextIndex])
      .call(scrollProject, [projectSliderRef.current, nextIndex]);

    return true;
  };

  const generateProjects = (projects) => {
    return (
      projects.map((project, key) => {
        return (
          <Project
            key={key}
            projectData={project.fields}
            projectId={project.sys.id}
            projectNumber={key + 1}
            onClick={openProject}
            projectActive={projectDetailsOpen}
          />
        );
      })
    );
  };

  return (
    <main
      className={styles.projectsContainer}
      style={!projectDetailsOpen ? {overflowY: 'hidden'} : {}} >
      <Header theme='light'>
        <div ref={projectSliderRef} className={styles.projectSlider}>
          {generateProjects(props.projectsList)}
        </div>
        <div className={styles.sliderBar} style={projectDetailsOpen ? {display: 'none'} : {}}>
          <span ref={sliderRef}></span>
        </div>
        <div className={styles.nextButton}>
          <img src={require('../../assets/images/grid.svg')} />
          <button onClick={handleNext}><span>n</span><span>ex</span><span>t</span></button>
        </div>
      </Header>

      {projectDetailsOpen &&
        <>
          <ProjectContent
            projectData={props.projectsList.find(project => project.sys.id === activeProjectId)?.fields} />
          <Footer />
        </>
      }
    </main>
  );
};

Projects.propTypes = {
  dispatch: PropTypes.func,
  projectsList: PropTypes.array,
  projectsAssets: PropTypes.array,
  projectsLoading: PropTypes.bool,
  projectsError: PropTypes.object,
  match: PropTypes.shape({
    params: {
      id: PropTypes.string
    }
  })
};

export default Projects;