/* global document */
import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Loader from '../Loader/Loader';
import styles from './Header.module.css';

import { Menu, MenuNavigation } from '../Menu/Menu';

const Header = ({ children, theme }) => {
  const [menuActive, changeMenuState] = useState(false);

  const menuClick = () => {
    changeMenuState(!menuActive);

    document.body.classList.toggle('menu-open');
  };

  const createHeaderContent = () => {
    return (
      <div className={styles.loadContainer}>
        <Loader />
        <Menu menuClick={menuClick} />
        <header className={theme === 'dark' && menuActive ? `${styles.header} ${styles.dark}` :
          theme === 'light' ? styles.header : styles.header}>
          <MenuNavigation
            menuClick={menuClick}
            themeLight={false} />
          {children}
        </header>
      </div>
    );
  };

  return createHeaderContent();
};

Header.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object
  ]),
  theme: PropTypes.string,
  activateMenu: PropTypes.func
};

export default Header;
