import {
  GET_SERVICES_START,
  GET_SERVICES_SUCCESS,
  GET_SERVICES_FAILURE
} from '../actions/services';

const initialState = {
  items: [],
  loading: true,
  error: null
};

export default function servicesReducer (state = initialState, action) {
  switch (action.type) {
    case GET_SERVICES_START:
      return {
        ...state,
        loading: true,
        error: null
      };

    case GET_SERVICES_SUCCESS:
      return {
        ...state,
        loading: false,
        items: action.payload.services.items
      };

    case GET_SERVICES_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        items: []
      };

    default:
      return state;
  }
}