import React, { useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { TimelineMax } from 'gsap';
import { Redirect } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import hoverEffect from 'hover-effect';

import styles from './FeaturedProject.module.css';
import { useIntersection } from 'react-use';

const FeaturedProject = ({ project, id }) => {
  const [navigate, setNavigate] = useState(false);

  const featuredProjectRef = useRef(null);
  const featureTitleRef = useRef(null);
  const featureImageRef = useRef();

  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });

  const fetaureTitleIntersection = useIntersection(featureTitleRef, {
    root: null,
    rootMargin: '0px',
    threshold: isMobile ? 0.5 : 1
  });

  const featuredProjectIntersection = useIntersection(featuredProjectRef, {
    root: null,
    rootMargin: '0px',
    threshold: isMobile ? 0.3 : 0.7
  });

  useEffect(() => {
    new hoverEffect({
      parent: featureImageRef.current,
      intensity: 0.2,
      image1: project.image.fields.file.url,
      image2: project.imageGallery[0].fields.file.url,
      displacementImage: project.imageGallery[0].fields.file.url,
    });
  }, [project.image.fields.file.url, project.imageGallery]);

  const fadeIn = (element) => {
    new TimelineMax()
      .to(element.current,
        {
          duration: 0.8,
          ease: 'power4.out',
          y: -80,
          opacity: 1,
        })
      .set(element.current,
        {
          className: '+=active'
        });
  };

  const animateFeaturedProject = (element) => {
    const featureImage = element.current.getElementsByClassName('featureImage');
    const projectDescriptuon = element.current.getElementsByClassName('projectDescription');
    new TimelineMax()
      .to(featureImage,
        {
          duration: 1.5,
          ease: 'power4.out',
          x: '0'
        })
      .to(projectDescriptuon,
        {
          duration: 0.6,
          ease: 'power2.out',
          y: -30,
          opacity: 1
        }, 0.7);

  };

  useEffect(
    () => {
      if (fetaureTitleIntersection && fetaureTitleIntersection.intersectionRatio > 0) {
        fadeIn(featureTitleRef);
      }

      if (featuredProjectIntersection && featuredProjectIntersection.intersectionRatio > 0) {
        animateFeaturedProject(featuredProjectRef);
      }
    },
    [fetaureTitleIntersection, featuredProjectIntersection]
  );

  if (navigate) {
    return (
      <Redirect
        to={{pathname: '/projects/' + id}}
        push
      />
    );
  }

  return (
    <div className={styles.featuredProject}>
      <div className={styles.titleContainer}>
        <h1 ref={featureTitleRef}>Featured<br />project</h1>
      </div>
      <div className={styles.featuredProjectContent}>
        <div
          ref={featuredProjectRef}
          className={styles.projectContainer}>
          <div className='projectDescription'>
            <div className={styles.content}>
              <h5>{project.title}</h5>
              <p>{project.description}</p>
              <button className='simpleButton' onClick={() => setNavigate(true)}>View project</button>
            </div>
          </div>
          <div className='projectImage'>
            <span className={styles.tagCover}><span>WEBSITE DESIGN + DEVELOPMENT</span></span>
            <div
              className={styles.featureImageContainer}>
              <div
                className='featureImage'
                ref={featureImageRef}>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

FeaturedProject.propTypes = {
  project: PropTypes.object,
  id: PropTypes.string
};

export default FeaturedProject;
