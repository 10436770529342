import React from 'react';
import PropTypes from 'prop-types';

import styles from './Project.module.css';

const Project = ({ projectData, projectNumber, projectId, onClick, projectActive }) => {
  const updateHtml = (e) => {
    e.preventDefault();

    onClick(null, projectId);
  };

  const renderProjectPageHeader = () => {
    return (
      <div className={'projectPageHeader'}>
        <h2>{projectData.title}<br />project</h2>
        <p className={styles.projectDescription}>{projectData.description}</p>
      </div>
    );
  };

  return (
    <div className='projectContainer' id={projectId}>
      <div
        id={projectId}
        className={'imageContainer grid-55'} >
        <div
          className={styles.backgroundImage}
          style={{ backgroundImage: `url(${projectData.image.fields.file.url})` }}>
        </div>
        <h1 className={'projectText'}>
          <span>p</span>
          <span>ro</span>
          <span>ject</span>
        </h1>
        <span className={styles.projectNumber}>{projectNumber}</span>
      </div>
      <div
        className={`${styles.projectContent} grid-45`}
        style={projectActive ? {opacity: 0} : null}>
        <div className={styles.projectContentTitles}>
          <h2>{projectData.title}</h2>
          <h4 className={styles.projectSubtitle}>{projectData.subtitle}</h4>
        </div>
        <div className={styles.projectContentDescription}>
          <p className={styles.projectDescription}>{projectData.description}</p>
          <button className='simpleButton' onClick={(e) => updateHtml(e)}>View project</button>
        </div>
      </div>
      {renderProjectPageHeader()}
    </div>
  );
};

Project.propTypes = {
  projectData: PropTypes.object,
  projectNumber: PropTypes.number,
  projectId: PropTypes.string,
  onClick: PropTypes.func,
  activateProjectPage: PropTypes.func,
  projectActive: PropTypes.bool
};

export default Project;