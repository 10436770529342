import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

import Header from '../../components/Header/Header';
import FeaturedProject from '../../components/FeaturedProject/FeaturedProject';
import Services from '../../components/Services/Services';
import ProjectsList from '../../components/ProjectsList/ProjectsList';
import AboutSection from '../../components/AboutSection/AboutSection';
import Footer from '../../components/Footer/Footer';

import styles from './Home.module.css';

const Home = (props) => {
  useEffect(() => {
    /* eslint-disable no-undef */
    window.scrollTo(0, 0);
    document.body.classList.add('homepage');
    document.body.classList.remove('projectPage');
    /* eslint-enable */
  }, []);

  let history = useHistory();

  const {
    servicesList,
    projectsList,
    projectsAssets
  } = props;

  return (
    <main className={styles.homePageContent}>
      <Header
        theme='light'>
        <>
          <div className={styles.headerContainer}>
            <h1>We build<br />the technology<br />of tomorrow.</h1>
            {/*<ul>
              <li>Design.</li>
              <li>Develop.</li>
              <li>Repeat.</li>
            </ul>*/}
          </div>

          <div className={styles.projectsButton}>
            <img alt='' src={require('../../assets/images/grid.svg')} />
            <button onClick={() => history.push('/projects')}>
              <span>p</span><span>ro</span><span>ject</span><span>s</span>
            </button>
          </div>
        </>
      </Header>

      <div className={styles.homeImageContainer}>
        <div
          className={styles.backgroundImage}
          style={{backgroundImage: `url(${require('../../assets/images/home-header-image-80.jpg')})`}}>
        </div>
      </div>

      <div className={'bodyContent'}>
        <AboutSection
          text={`Take your brand to the next level
          with a purpose-built website, application
          or backend.`} />

        <FeaturedProject
          project={projectsList.find(project => project.fields.isFeatured === true)?.fields}
          id={projectsList.find(project => project.fields.isFeatured === true)?.sys.id} />

        <Services
          services={servicesList} />

        <ProjectsList
          projects={projectsList}
          assets={projectsAssets} />
      </div>

      <Footer />
    </main>
  );
};

Home.propTypes = {
  dispatch: PropTypes.func,
  servicesList: PropTypes.array,
  projectsList: PropTypes.array,
  projectsAssets: PropTypes.array,
};

export default Home;
