/* global window, document */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import CountUp from 'react-countup';

import styles from './Loader.module.css';

const Loader = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const completeLoad = () => {
    document.body.classList.add('loaded');
  };

  return (
    <div className={styles.loadingContainer}>
      <h1>
        <CountUp
          start={0}
          end={100}
          delay={2}
          duration={3}
          prefix={'0'}
          onEnd={() => completeLoad()} />
      </h1>
      <div className={styles.loaderContentContainer}>
        <h6>Loading</h6>
      </div>
    </div>
  );
};

Loader.propTypes = {
  removeLoader: PropTypes.func
};

export default Loader;
