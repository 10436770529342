import React from 'react';
import PropTypes from 'prop-types';

import styles from './SocialIcons.module.css';

function SocialIcons (props) {
  return (
    <ul className={
      props.vertical ? `${styles.socialIcons} vertical`
        : props.horizontal ? `${styles.socialIcons} horizontal`
          : styles.socialIcons}>
      <li>
        <a
          href='https://www.facebook.com/whtspcstudio'
          target='_blank'
          rel='noopener noreferrer'>
          <img
            src={props.light ?
              require('../../assets/images/social-icons/facebook-icon-w.png') :
              require('../../assets/images/social-icons/facebook-icon.png')}
            alt="Facebook" />
        </a>
      </li>
      <li>
        <a
          href='https://www.instagram.com/whtspc_studio/'
          target='_blank'
          rel='noopener noreferrer'>
          <img
            src={props.light ?
              require('../../assets/images/social-icons/instagram-icon-w.png') :
              require('../../assets/images/social-icons/instagram-icon.png')}
            alt="Instagram" />
        </a>
      </li>
    </ul>
  );
}

SocialIcons.propTypes = {
  vertical: PropTypes.bool,
  horizontal: PropTypes.bool,
  light: PropTypes.bool,
};


export default SocialIcons;
